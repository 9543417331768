'use client';

import { Toaster } from 'sonner';
import React, { ReactNode } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { I18nProviderClient } from '@/locales/client';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      staleTime: 1000 * 30,
    },
  },
});

/**
 * @param props the main prop object
 * @param props.children The React Node containing children elements being rendered inside of this component
 * @param props.locale i18n
 * @returns The providers that need a client component to load correctly
 */
const Providers = ({
  children,
  locale,
}: {
  children: ReactNode;
  locale: string;
}) => {
  return (
    <I18nProviderClient locale={locale}>
      <QueryClientProvider client={queryClient}>
        <Toaster richColors className="!font-sans" />
        <ReactQueryDevtools
          buttonPosition={'bottom-left'}
          initialIsOpen={false}
        />
        {children}
      </QueryClientProvider>
    </I18nProviderClient>
  );
};

export default Providers;
